import '../css/app.css';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { ZiggyVue, Ziggy } from 'ziggy-js/dist/vue';
import InstantSearch from 'vue-instantsearch/vue3/es';
import { createGtm } from '@gtm-support/vue-gtm';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import notifications from './shared/notifications';
import mixpanel from './shared/mixpanel';
import echo from './shared/echo';
import withSentry from './withSentry';
import withConfig from './withConfig';

createInertiaApp({
  resolve: (name) => resolvePageComponent(`./${name}.vue`, import.meta.glob('./**/views/**/*.vue')),
  progress: {
    color: '#5359EA',
    showSpinner: false,
  },
  setup({
    el, App, props, plugin,
  }) {
    return withSentry(withConfig(createApp({ render: () => h(App, props) })))
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(notifications)
      .use(InstantSearch)
      .use(mixpanel)
      .use(echo)
      .use(createGtm({
        id: 'GTM-TD78ZG3',
      }))
      .mount(el);
  },
});
