<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M10.3333 15.6667L13 13M13 13L15.6667 10.3333M13 13L10.3333 10.3333M13 13L15.6667 15.6667M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
