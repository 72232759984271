<template>
  <div class="max-w-sm w-full pointer-events-auto border border-gray-300 rounded-lg">
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <Component
            :is="getNotification(notification.type).component"
            class="w-6 h-6"
            :class="getNotification(notification.type).color"
            :title="getNotification(notification.type).title"
          />
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">
            {{ notification.title }}
          </p>
          <p class="mt-1 text-sm text-gray-500">
            {{ notification.message }}
          </p>
          <a
            v-if="notification.link"
            :href="notification.link.href"
            class="mt-1 text-sm text-primary-600 underline"
          >
            {{ notification.link.text }}
          </a>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button
            type="button"
            class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="closeNotification(notification.id)"
          >
            <span class="sr-only">Close</span>
            <XIcon
              class="h-5 w-5"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from '../icons/CheckCircle.vue';
import InformationCircleIcon from '../icons/InformationCircle.vue';
import ExclamationCircleIcon from '../icons/ExclamationCircle.vue';
import CrossCircleIcon from '../icons/CrossCircle.vue';
import XIcon from '../icons/X.vue';
import { methods } from './notifications';
import Mapper from './notificationMapper';

export default {
  name: 'Notification',
  components: {
    CheckCircleIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
    CrossCircleIcon,
    XIcon,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeNotification(id) {
      methods.closeNotification(id);
    },
    getNotification(notificationType) {
      return Mapper(notificationType);
    },
  },
};
</script>
