<template>
  <Teleport to="#notifications-portal">
    <TransitionGroup
      :enter-active-class="notifications.length > 1
        ? transitionGroupClasses.enterActiveClassDelayed
        : transitionGroupClasses.enterActiveClass"
      :enter-from-class="transitionGroupClasses.enterFromClass"
      :enter-to-class="transitionGroupClasses.enterToClass"
      :leave-active-class="transitionGroupClasses.leaveActiveClass"
      :leave-from-class="transitionGroupClasses.leaveFromClass"
      :leave-to-class="transitionGroupClasses.leaveToClass"
      :move-class="transitionGroupClasses.moveClass"
    >
      <slot
        :notifications="notifications"
      />
    </TransitionGroup>
  </Teleport>
</template>

<script>
import { TransitionGroup, Teleport } from 'vue';
import { state, maxNotifications } from './notifications';

export default {
  components: {
    TransitionGroup,
    Teleport,
  },
  props: {
    transitionGroupClasses: {
      type: Object,
      default: () => ({
        enterActiveClassDelayed: 'transform ease-out duration-300 transition delay-300',
        enterActiveClass: 'transform ease-out duration-300 transition',
        enterFromClass: 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4',
        enterToClass: 'translate-y-0 opacity-100 sm:translate-x-0',
        leaveActiveClass: 'transition ease-in duration-500',
        leaveFromClass: 'opacity-100',
        leaveToClass: 'opacity-0',
        moveClass: 'transition duration-500',
      }),
    },
  },
  emits: [
    'close',
  ],
  data() {
    return {
      notifications: state.notifications,
    };
  },
  computed: {
    sortedNotifications() {
      return [...this.notifications]
        .reverse()
        .slice(0, maxNotifications);
    },
    isShadowing() {
      // eslint-disable-next-line vue/max-len
      return this.$page.props.shadowing.isShadowingUser === true || this.$page.props.shadowing.isShadowingBranch === true;
    },
  },
  mounted() {
    const notificationsPortal = document.getElementById('notifications-portal');

    if (this.isShadowing === true) {
      notificationsPortal.setAttribute('class', 'fixed right-0 z-20 overflow-y-auto w-96 overflow-x-hidden mt-16 p-4 pointer-events-none top-12');
    }
  },
  beforeCreate() {
    let notificationsPortal = document.getElementById('notifications-portal');

    if (notificationsPortal) {
      notificationsPortal.remove();
    }

    notificationsPortal = document.createElement('div');
    notificationsPortal.setAttribute('id', 'notifications-portal');
    notificationsPortal.setAttribute('class', 'fixed top-0 right-0 z-20 overflow-y-auto w-96 overflow-x-hidden mt-16 p-4 pointer-events-none');

    document.body.appendChild(notificationsPortal);
  },
};
</script>
