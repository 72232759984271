import { isInteger, uniqueId } from 'lodash';
import { reactive } from 'vue';

const DEFAULT_TIMEOUT = 5000; // 5 seconds

export const notificationTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const maxNotifications = 10;

export const state = reactive({
  notifications: [],
});

/**
 * @param {string} id
 */
const removeNotification = (id) => {
  state.notifications.splice(state.notifications.findIndex((notification) => notification.id === id), 1);
};

/**
 * @param {{title: string, message: string, type: string, duration: number}} notification
 * @return string
 */
const notify = (notification) => {
  const { duration } = notification;
  const id = uniqueId('notification-');

  state.notifications.push({ ...notification, id });

  if (duration < 0) return id;

  setTimeout(() => {
    removeNotification(id);
  }, isInteger(duration) ? duration : DEFAULT_TIMEOUT);
  return id;
};

export const methods = {
  /**
   * @param {{title: string, message: string, duration: number}} notification
   * @return string
   */
  notifySuccess(notification) {
    const type = notificationTypes.SUCCESS;
    return notify({ ...notification, type });
  },

  /**
   * @param {{title: string, message: string, duration: number}} notification
   * @return string
   */
  notifyInfo(notification) {
    const type = notificationTypes.INFO;
    return notify({ ...notification, type });
  },

  /**
   * @param {{title: string, message: string, duration: number}} notification
   * @return string
   */
  notifyWarning(notification) {
    const type = notificationTypes.WARNING;
    return notify({ ...notification, type });
  },

  /**
   * @param {{title: string, message: string, duration: number}} notification
   * @return string
   */
  notifyError(notification) {
    const type = notificationTypes.ERROR;
    return notify({ ...notification, type });
  },

  /**
   * @param {string} id
   */
  closeNotification(id) {
    removeNotification(id);
  },
};
