import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';

/**
 * @param {(client: import('@sentry/fullstory/dist/types').FullStoryClient|null) => void} callback
 */
const initFullstory = (callback) => {
  if (!import.meta.env.FRONTEND_FULLSTORY_ORG_ID) {
    callback(null);
    return;
  }

  try {
    FullStory.init({
      orgId: import.meta.env.FRONTEND_FULLSTORY_ORG_ID,
      namespace: '_fs',
      debug: import.meta.env.MODE === 'development',
      devMode: import.meta.env.DEV,
    }, ({ sessionUrl }) => {
      const client = {
        // eslint-disable-next-line
        getCurrentSessionURL: (now) => sessionUrl,
        event: FullStory.event,
      };

      callback(client);
    });
  } catch (e) {
    callback(null);
  }
};

const initSentry = (fullStoryClient = null) => {
  const integrations = [new BrowserTracing()];

  if (fullStoryClient && import.meta.env.FRONTEND_SENTRY_SLUG) {
    integrations.push(
      new SentryFullStory(import.meta.env.FRONTEND_SENTRY_SLUG, { client: fullStoryClient }),
    );
  }

  Sentry.init({
    dsn: import.meta.env.FRONTEND_SENTRY_DSN,
    integrations,
    // eslint-disable-next-line no-undef
    release: __RELEASE_HASH__,
  });
};

export default (app) => {
  if (import.meta.env.FRONTEND_SENTRY_DSN) {
    initFullstory((client) => initSentry(client));

    const sentryApp = app.mixin(Sentry.createTracingMixins({ trackComponents: true }));
    Sentry.attachErrorHandler(sentryApp, { logErrors: true });

    return sentryApp;
  }

  return app;
};
