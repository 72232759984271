/* eslint-disable no-param-reassign */
import mixpanel from 'mixpanel-browser';
import { usePage } from '@inertiajs/vue3';

mixpanel.init(import.meta.env.FRONTEND_MIXPANEL_TOKEN);

let identified = false;

const identify = () => {
  const { user } = usePage().props;

  const identity = {
    user: user.name ?? null,
    user_id: user.id ?? null,
    account: user.account.name ?? null,
    account_id: user.account_id ?? null,
  };

  if (!identity.user_id) {
    return {};
  }

  if (identified) {
    return identity;
  }

  mixpanel.identify(identity.user_id);

  identified = true;

  return identity;
};

const updatePerson = (attributes) => {
  if (attributes && identify()) {
    mixpanel.people.set(attributes);
  }
};

const track = (eventName, eventData) => {
  if (!import.meta.env.FRONTEND_MIXPANEL_TOKEN) {
    return;
  }

  mixpanel.track(eventName, {
    ...eventData,
    ...identify(),
  });
};

export default {
  install(app) {
    app.config.globalProperties.$track = track;
    app.config.globalProperties.$updatePerson = updatePerson;
    app.provide('updatePerson', app.config.globalProperties.$updatePerson);
  },
};
