// eslint-disable-next-line import/no-extraneous-dependencies
import Echo from 'laravel-echo';
// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher from 'pusher-js';

/**
 * @typedef {Object} Echo
 * @property {function(string, string, function(Object):void):void} private
 */

export class EchoService {
  constructor() {
    this.echo = null;
  }

  /**
   * @param {Object} options
   * @return {EchoService}
   */
  connect(options = {}) {
    if (this.echo instanceof Echo) {
      return this;
    }

    try {
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.FRONTEND_VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.FRONTEND_VITE_PUSHER_APP_CLUSTER,
        forceTLS: true,
        ...options,
      });

      this.echo.Pusher = Pusher;

      if (import.meta.env.DEV) {
        this.echo.Pusher.logToConsole = true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return this;
  }

  /**
   * @param {string} channel
   * @param {string} event
   * @param {Function} callback
   */
  private(channel, event, callback) {
    this.echo?.private(channel)
      .listen(event, callback);
  }
}

export default {
  install(app) {
    if (import.meta.env.FRONTEND_VITE_PUSHER_APP_KEY) {
      const echo = new EchoService().connect();
      // eslint-disable-next-line no-param-reassign
      app.config.globalProperties.$echo = echo;
      app.provide('echo', echo);
    }
  },
};
