import { notificationTypes } from './notifications';

/**
 * @param {string} componentType
 * @returns {Object}
 */
export default function Mapper(componentType) {
  const map = {
    [notificationTypes.SUCCESS]: {
      component: 'CheckCircleIcon',
      color: 'text-green-400',
      title: 'Success',
    },
    [notificationTypes.INFO]: {
      component: 'InformationCircleIcon',
      color: 'text-primary-400',
      title: 'Info',
    },
    [notificationTypes.WARNING]: {
      component: 'ExclamationCircleIcon',
      color: 'text-amber-400',
      title: 'Warning',
    },
    [notificationTypes.ERROR]: {
      component: 'CrossCircleIcon',
      color: 'text-red-400',
      title: 'Error',
    },
  };

  return map[componentType];
}
