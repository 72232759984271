import Notifications from './Notifications.vue';
import Notification from './Notification.vue';
import { methods } from './notifications';

let installed = false;

export default {
  /* eslint-disable no-param-reassign */
  install(app) {
    if (installed) {
      return;
    }

    app.component('Notifications', Notifications);
    app.component('Notification', Notification);

    app.config.globalProperties.$notify = {};
    app.config.globalProperties.$notify.success = (data) => methods.notifySuccess(data);
    app.config.globalProperties.$notify.info = (data) => methods.notifyInfo(data);
    app.config.globalProperties.$notify.warning = (data) => methods.notifyWarning(data);
    app.config.globalProperties.$notify.error = (data) => methods.notifyError(data);
    app.config.globalProperties.$notify.closeNotification = (data) => methods.closeNotification(data);
    app.provide('notify', app.config.globalProperties.$notify);

    installed = true;
  },
};
